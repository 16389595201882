import React, { useState, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import "./card.component.css";
import Dialog from "@material-ui/core/Dialog";
// import Details from "../../assets/writing_749000.webp";
// import petPhoto from "../../assets/catdog1.jpg";
import Tooltip from "@material-ui/core/Tooltip";
import { DialogTitle, DialogActions } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { switchUrls } from "../../api/index.js";
import { BsThreeDots } from "react-icons/bs";
const baseURL = switchUrls("gateway");

function Activecard({
  pet_bloodgrp,
  pet_age,
  pet_dob,
  pet_color,
  pet_imagelocation,
  pet_breed,
  pet_ident,
  pet_name,
  pet_gender,
  tag_number,
  activation_date,
  tag_status,
  lf_status,
  valuable_type,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  let usertoken = readCookie("token");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  // const [lfstatus, setlfstatus] = useState("lost");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const isDataLoaded =
    pet_name !== null &&
    pet_breed !== null &&
    pet_ident !== null &&
    pet_gender !== null &&
    pet_age !== null &&
    activation_date !== null;
  const token = usertoken;
  const uid = localStorage.getItem("uid");

  function activateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/activeTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Activated") {
          window.location.href = "#/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function deactivateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/deactivateTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Deactivated") {
          window.location.href = "#/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  // define the function that finds the users geolocation
  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          axios
            .post(
              "/api/reportfoundstatus",
              { tag_number, latitude, longitude },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data === "Tag is reported found") {
                setShow2(false);

                window.location.reload();
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const handleQrClick = () => {
    window.location.href = `/#/scan/${tag_number}`;
  };
  useEffect(() => {
    switch (valuable_type) {
      case "Dog":
        setImageSrc("https://storage.googleapis.com/pettag/qr/assets/dog.png");
        break;
      case "Cat":
        setImageSrc("https://storage.googleapis.com/pettag/qr/assets/cat.png");
        break;
      default:
        return null;
    }
  }, [valuable_type]);
  function reportFound() {
    getUserLocation();
  }

  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date);
    const day = tempDate.getUTCDate().toString().padStart(2, "0");
    const month = tempDate.toLocaleString("default", { month: "short" });
    const year = tempDate.getUTCFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setShow(false);
  };

  // function foundOrLost() {

  // }

  // Convert Camel Case
  const convertCamelCase = (sentense) => {
    if (typeof sentense !== "string") {
      return sentense;
    }
    const words = sentense?.split(" ");

    const capitalizeFirstWord = words?.map((word) => {
      if (word.length > 0) {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
      } else {
        return word;
      }
    });
    const capitalizeFirstWordSentese = capitalizeFirstWord?.join(" ");
    return capitalizeFirstWordSentese;
  };

  const handleConfirm = () => {
    setShow(false);

    //  tag_status === "active" ? deactivateTag : activateTag;
    if (tag_status === "active") {
      deactivateTag();
    } else {
      activateTag();
    }

    window.location.reload();
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    // console.log("Error loading image");
    setImageLoaded(true);
  };
  return (
    <>
      <div className="card_main">
        <div className="card_main_div">
          <div className="imagedetails">
            <div>
              <div>
                {pet_imagelocation !== null && (
                  <div
                    className="loader"
                    style={{ display: imageLoaded ? "none" : "block" }}
                  >
                    <img
                      src={
                        "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                      }
                      alt="Loading..."
                      loading="lazy"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                )}
                {pet_imagelocation !== null ? (
                  <img
                    className="pet-img"
                    src={pet_imagelocation}
                    alt=""
                    // loading="lazy"
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: "15px",
                      display: imageLoaded ? "block" : "none",
                    }}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                ) : (
                  <img
                    className="pet-img"
                    src={imageSrc}
                    alt=""
                    // loading="lazy"
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: "15px",
                      // display: imageLoaded ? "block" : "none",
                    }}
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                )}
              </div>
              <div className="qrscan-img">
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                  style={{ width: "20px", height: "20px" }}
                />
                <p style={{ fontSize: "0.85rem" }}>
                  <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
                </p>
              </div>

              <div className="mobile-view">
                <ul
                  className="tagdetails"
                  style={{
                    listStyle: "none",
                    color: "black",
                    textAlign: "right",
                    marginRight: 20,
                    fontSize: "12px",
                    lineHeight: "normal",
                  }}
                >
                  {/* <li className="petownerid">
                  <span>{tag_number}</span>
                </li> */}
                  {lf_status === "lost" ? (
                    <li className="petloststatus mobile-view-lostpetstatus">
                      <span>Reported Lost</span>
                    </li>
                  ) : (
                    <li className="petstatus mobile-view-petstatus">
                      {tag_status === "active" ? (
                        <Tooltip
                          title="Click here to deactivate tag"
                          aria-label="add"
                        >
                          <button
                            className="activatedeactivatebutton bg-success mt-4"
                            onClick={() => {
                              setShow(true);
                              handleClose();
                            }}
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Click here to activate tag"
                          aria-label="add"
                        >
                          <button
                            className="activatedeactivatebutton bg-danger mt-4"
                            onClick={() => {
                              setShow(true);
                              handleClose();
                            }}
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                      )}
                      <span>{`${
                        tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                      }`}</span>
                    </li>
                  )}
                  {/* <li className="petactivationdate mobile-view-petactivationdate">
                    <div className="flex-container">
                      <span className="petactivedate-lebal">Active Date: </span>
                      <span className="date-value">
                        {convertISOStringToMonthDay(activation_date)}
                      </span>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="middle-content">
            <ul className="geniecardtext-ul">
              <li className="petdetails">
                <span className="lebal">Name: </span>
                <span className="lebal-content">
                  {convertCamelCase(pet_name)}
                </span>
              </li>
              <li className="petdetails">
                <span className="lebal">Breed: </span>
                <span className="lebal-content">
                  {isDataLoaded ? (
                    convertCamelCase(pet_breed)
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </span>
              </li>
              <li className="petdetails">
                <span className="lebal">Identity: </span>
                <span className="lebal-content">
                  {convertCamelCase(pet_ident)}
                </span>
              </li>
              <li className="petdetails">
                <span className="lebal">Gender: </span>
                <span className="lebal-content">
                  {isDataLoaded ? (
                    convertCamelCase(pet_gender)
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </span>
              </li>
              <li className="petdetails">
                <span className="lebal">Age: </span>
                <span className="lebal-content">
                  {isDataLoaded ? (
                    `${pet_age} Years`
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </span>
              </li>
              <li className="petdetails date-laptopview">
                {/* <div className="flex-container">
                  <span className="petactivedate-lebal">Active Date: </span>
                  <span className="date-value">
                    {convertISOStringToMonthDay(activation_date)}
                  </span>
                </div> */}
                <span className="lebal">Active Date: </span>
                <span className="lebal-content">
                  {isDataLoaded ? (
                    convertISOStringToMonthDay(activation_date)
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </span>
              </li>
            </ul>
            <ul
              className="tagdetails laptop-view"
              style={{
                listStyle: "none",
                color: "black",
                textAlign: "right",
                marginRight: 20,
                // fontSize: "12px",
                lineHeight: "normal",
              }}
            >
              {/* <li className="petownerid">
                  <span>{tag_number}</span>
                </li> */}
              {lf_status === "lost" ? (
                <li className="petloststatus mt-3">
                  <span>Reported Lost</span>
                </li>
              ) : (
                <li className="petstatus">
                  {tag_status === "active" ? (
                    <Tooltip
                      title="Click here to deactivate tag"
                      aria-label="add"
                    >
                      <button
                        className="activatedeactivatebutton bg-success mt-4"
                        onClick={() => {
                          setShow(true);
                          handleClose();
                        }}
                        style={{ marginRight: "4px" }}
                      ></button>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Click here to activate tag"
                      aria-label="add"
                    >
                      <button
                        className="activatedeactivatebutton bg-danger mt-4"
                        onClick={() => {
                          setShow(true);
                          handleClose();
                        }}
                        style={{ marginRight: "4px" }}
                      ></button>
                    </Tooltip>
                  )}
                  <span>{`${
                    tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                  }`}</span>
                </li>
              )}
              <li className="petactivationdate">
                <span className="petactive-lebal">Active Date:</span>
                <span span className="lebal-content">
                  {isDataLoaded ? (
                    convertISOStringToMonthDay(activation_date)
                  ) : (
                    <Skeleton variant="text" width={100} />
                  )}
                </span>
              </li>
            </ul>
          </div>

          <div className="tagdetailsandicons">
            <div className="icons" style={{ marginRight: "30px" }}>
              <Tooltip title="Click here to try QR Scan" aria-label="add">
                <div onClick={handleQrClick} style={{ cursor: "pointer" }}>
                  <img
                    className="images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/qr.png"
                    }
                    alt="Pet Icon"
                  />
                </div>
              </Tooltip>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <Tooltip title="Pet Parent Details" aria-label="add">
                <a
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/pet-parent-details/" + tag_number;
                  }}
                >
                  <img
                    className="details-images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/parent.png"
                    }
                    alt="Pet Icon"
                  />
                </a>
              </Tooltip>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <Tooltip title="Pet Medical Details" aria-label="add">
                <a
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/medical-dashboard/" + tag_number;
                  }}
                >
                  {/* <i
                    className="fas fa-notes-medical"
                    style={{
                      color: "black",
                      fontSize: 18,
                      marginTop: 10,
                      marginRight: 1,
                    }}
                  ></i> */}
                  <img
                    className="images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/medical-file.png"
                    }
                    alt="Pet Icon"
                  />
                </a>
              </Tooltip>
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <Tooltip title="Pet Details" aria-label="add">
                <a
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/pet-details/" + tag_number;
                  }}
                >
                  {/* <i
                    style={{
                      color: "black",
                      fontSize: 18,
                      marginTop: 10,
                      marginRight: 1,
                    }}
                  ></i> */}
                  <img
                    className="images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/edits.png"
                    }
                    alt="Pet Icon"
                  />
                </a>
              </Tooltip>
              {/* &nbsp;&nbsp;&nbsp; */}
              <i
                aria-controls="menu-list-grow"
                onClick={handleClick}
                className="fa fa-ellipsis-v menu-drop"
                style={{
                  color: "#ffc200f7",
                  fontSize: 27,
                  marginTop: 1,
                  // marginRight: "-40px",
                  // marginLeft: "-10px",
                  // paddingLeft: "10px",
                  // paddingRight: "10px",
                }}
              ></i>
              {/* <img
                className="images-css"
                src={"https://storage.googleapis.com/pettag/qr/assets/list.png"}
                onClick={handleClick}
                alt="Pet Icon"
                style={{
                  fontSize: 15,
                  cursor: "pointer",
                }}
              /> */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    if (lf_status === "lost") {
                      setShow2(true);

                      handleClose();
                    } else {
                      window.location.href = "/reportlost/" + tag_number;
                      setShowLoader(true);
                      setTimeout(() => {
                        setShowLoader(false);
                      }, 5000);
                    }
                  }}
                >
                  {lf_status === "lost" ? "Report Found" : "Report Lost"}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setShow(true);
                    handleClose();
                  }}
                >
                  {tag_status === "active" ? "Deactivate Tag" : "Activate Tag"}
                </MenuItem>

                {lf_status === "lost" ? (
                  <MenuItem
                    onClick={() => {
                      setShowLoader(true);
                      setTimeout(() => {
                        setShowLoader(false);
                      }, 5000);
                      window.location.href = "/pet-poster/" + tag_number;
                    }}
                  >
                    Pet Poster
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/pet-details/" + tag_number;
                  }}
                >
                  Pet Details
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/pet-parent-details/" + tag_number;
                  }}
                >
                  Pet Parent Details
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/medical-dashboard/" + tag_number;
                  }}
                >
                  Medical Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/#/scan/" + tag_number;
                  }}
                >
                  Preview QR Scan
                </MenuItem>
              </Menu>
              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show}
                onClose={handleClose1}
                // aria-labelledby="form-dialog-title"
              >
                <DialogTitle style={{ fontFamily: "Prompt" }}>
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button
                    // onClick={tag_status === "active" ? deactivateTag : activateTag}
                    onClick={handleConfirm}
                    className="activeCard__confirm"
                  >
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show2}
                onClose={handleClose1}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle>
                  {"Are you sure you want to report found?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button onClick={reportFound} className="activeCard__confirm">
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        {showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Activecard;
