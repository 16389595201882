import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import "./card.component.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import "./keycard.component.css";
import { BsThreeDots } from "react-icons/bs";
function AcitveEarphoneCard({
  earphone_brand,
  earphone_color,
  warranty_period,
  earphone_imagelocation,
  valuable_type,
  tag_number,
  activation_date,
  Date_purchase,
  tag_status,
  lf_status,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  let usertoken = readCookie("token");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [lfstatus, setlfstatus] = useState("lost");
  const [imageLoaded, setImageLoaded] = useState(false);
  const token = usertoken;
  const uid = localStorage.getItem("uid");

  function activateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/earphoneactiveTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Activated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function deactivateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/earphonedeactivateTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Deactivated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          axios
            .post(
              "/api/earphonereportfoundstatus",
              { tag_number, latitude, longitude },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data === "Tag is reported found") {
                window.location.href = "/dashboard/" + uid;
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const handleQrClick = () => {
    window.location.href = `/#/scan/${tag_number}`;
  };
  function reportFound() {
    getUserLocation();
  }
  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date);
    const day = tempDate.getUTCDate().toString().padStart(2, "0");
    const month = tempDate.toLocaleString("default", { month: "short" });
    const year = tempDate.getUTCFullYear();
    const formattedDate = `${day} ${month}, ${year}`;
    return formattedDate;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setShow(false);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  function foundOrLost() {
    if (lf_status === "lost") {
      setShow2(true);

      handleClose();
    } else {
      const redirectLink = "/earphone/report-lost/" + tag_number;

      window.location.href = redirectLink;
    }
  }

  return (
    <div className="card_main">
      <div className="card_main_div">
        <div
          className="imagedetails"
          style={{ margin: 5, display: "flex", justifyContent: "center" }}
        >
          <div>
            <div
              style={{
                display: imageLoaded ? "none" : "block",
                width: "100px",
                height: "100px",
              }}
            >
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <img
              src={
                earphone_imagelocation
                  ? earphone_imagelocation
                  : "https://storage.googleapis.com/pettag/qr/assets/earphone.png"
              }
              alt="earphone"
              style={{
                width: 100,
                height: 100,
                // borderRadius: 50,
                marginTop: 7,
                display: imageLoaded ? "block" : "none",
              }}
              onLoad={handleImageLoad}
            />
            <div className="qrscan-img">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
              />
              <p style={{ fontSize: "0.85rem" }}>
                <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
              </p>
            </div>
            <div className="mobile-view">
              <ul
                className="tagdetails"
                style={{
                  listStyle: "none",
                  color: "black",
                  textAlign: "right",
                  marginRight: 20,
                  fontSize: "12px",
                  lineHeight: "normal",
                }}
              >
                {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}
                {lf_status === "lost" ? (
                  <li className="petloststatus mobile-view-lostpetstatus">
                    <span>Reported Lost</span>
                  </li>
                ) : (
                  <li className="petstatus mobile-view-petstatus">
                    {tag_status === "active" ? (
                      <Tooltip
                        title="Click here to deactivate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-success mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Click here to activate tag"
                        aria-label="add"
                      >
                        <button
                          className="activatedeactivatebutton bg-danger mt-4"
                          onClick={() => {
                            setShow(true);
                            handleClose();
                          }}
                          style={{ marginTop: 15, marginRight: 1 }}
                        ></button>
                      </Tooltip>
                    )}
                    <span>{`${
                      tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                    }`}</span>
                  </li>
                )}
                {/* <li className="petactivationdate">
                  <span>{`Active Date:${convertISOStringToMonthDay(
                    activation_date
                  )}`}</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="middle-content">
          <ul
            // className="bagcardtext"
            // style={{
            //   textAlign: "left",
            //   listStyle: "none",
            //   display: "inline-block",
            //   color: "black",
            //   marginLeft: 5,
            //   lineHeight: "normal",
            //   fontSize: "16px",
            // }}
            className="geniecardtext-ul"
          >
            {valuable_type ? (
              <li className="petdetails">
                <span className="lebal">Valuable Type: </span>
                <span className="lebal-content">{valuable_type}</span>
              </li>
            ) : (
              ""
            )}
            {earphone_brand ? (
              <li className="petdetails">
                <span className="lebal">Brand: </span>
                {earphone_brand?.slice(0, 15)}
                {earphone_brand?.length > 15 ? (
                  <BsThreeDots className="mt-1" />
                ) : (
                  ""
                )}
              </li>
            ) : (
              ""
            )}
            <li className="petdetails">{earphone_color}</li>
            {Date_purchase !== undefined ? (
              <li className="petdetails">
                <span className="lebal">Purchase Date: </span>
                <span className="lebal-content">
                  {convertISOStringToMonthDay(Date_purchase)}
                </span>
              </li>
            ) : (
              <li className="petdetails">
                <span className="lebal">Purchase Date: </span>
                <span className="lebal-content">
                  Purchase Date Not Specified
                </span>
              </li>
            )}
          </ul>
          <ul
            className="tagdetails laptop-view"
            style={{
              listStyle: "none",
              color: "black",
              textAlign: "right",
              marginRight: 20,
              fontSize: "12px",
              lineHeight: "normal",
            }}
          >
            {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}

            {lf_status === "lost" ? (
              <li className="petloststatus mobile-view-lostpetstatus">
                <span>Reported Lost</span>
              </li>
            ) : (
              <li className="petstatus">
                {tag_status === "active" ? (
                  <Tooltip
                    title="Click here to deactivate tag"
                    aria-label="add"
                  >
                    <button
                      className="activatedeactivatebutton bg-success mt-4"
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      style={{ marginTop: 15, marginRight: 1 }}
                    ></button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Click here to activate tag" aria-label="add">
                    <button
                      className="activatedeactivatebutton bg-danger mt-4"
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      style={{ marginTop: 15, marginRight: 1 }}
                    ></button>
                  </Tooltip>
                )}
                <span>{`${
                  tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                }`}</span>
              </li>
            )}
            <li className="petactivationdate">
              <span>{`Active Date:${convertISOStringToMonthDay(
                activation_date
              )}`}</span>
            </li>
          </ul>
        </div>
        <div className="tagdetailsandicons">
          <div className="icons" style={{ marginRight: "30px" }}>
            <Tooltip title="Click here to try QR Scan" aria-label="add">
              <div onClick={handleQrClick} style={{ cursor: "pointer" }}>
                <img
                  className="images-css"
                  src={"https://storage.googleapis.com/pettag/qr/assets/qr.png"}
                  alt="Pet Icon"
                />
              </div>
            </Tooltip>

            <Tooltip title="Earphone Details" aria-label="add">
              <a
                onClick={() => {
                  setShowLoader(true);
                  setTimeout(() => {
                    setShowLoader(false);
                  }, 5000);
                  window.location.href = "/earphone-details/" + tag_number;
                }}
              >
                {/* <i
                    style={{
                      color: "black",
                      fontSize: 18,
                      marginTop: 10,
                      marginRight: 1,
                    }}
                  ></i> */}
                <img
                  className="images-css"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/edits.png"
                  }
                  alt="Pet Icon"
                />
              </a>
            </Tooltip>

            <i
              aria-controls="menu-list-grow"
              onClick={handleClick}
              className="fa fa-ellipsis-v menu-drop"
              style={{
                color: "#ffc200f7",
                fontSize: 27,
                marginTop: 1,
                // marginRight: "-40px",
                // marginLeft: "-10px",
                // paddingLeft: "10px",
                // paddingRight: "10px",
              }}
            ></i>
            {/* <img
              className="images-css"
              src={"https://storage.googleapis.com/pettag/qr/assets/list.png"}
              onClick={handleClick}
              alt="Pet Icon"
              style={{
                // color: "black",
                fontSize: 15,
                // marginTop: 10,
                // paddingLeft: "10px",
                // paddingRight: "10px",
              }}
            /> */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  if (lf_status === "lost") {
                    setShow2(true);

                    handleClose();
                  } else {
                    // window.location.href = "/earphone/report-lost" + tag_number;
                    axios
                      .post(
                        "/api/earphonereportlost/" + tag_number,
                        { lfstatus },
                        {
                          headers: { Authorization: token },
                        }
                      )
                      .then((response) => {
                        // console.log(response);
                        // console.log(response.data);
                        if (response.data === "Tag is reported Lost") {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }
                      })
                      .catch((error) => {
                        // console.log(error);

                        this.setState({
                          snackbaropen: true,
                          snackbarmsg: "Report Lost Failed",
                        });
                      });
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                  }
                }}
              >
                {lf_status === "lost" ? "Report Found" : "Report Lost"}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShow(true);
                  handleClose();
                }}
              >
                {tag_status === "active" ? "Deactivate Tag" : "Activate Tag"}
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setShowLoader(true);
                  setTimeout(() => {
                    setShowLoader(false);
                  }, 5000);
                  window.location.href = "/earphone-details/" + tag_number;
                }}
              >
                Earphone Details
              </MenuItem>

              {lf_status === "lost" ? (
                <MenuItem
                  onClick={() => {
                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                    window.location.href = "/earphone-poster/" + tag_number;
                  }}
                >
                  Earphone Poster
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => {
                  window.location.href = "/#/scan/" + tag_number;
                }}
              >
                Preview QR Scan
              </MenuItem>
            </Menu>
            <Dialog
              open={show}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={
                      tag_status === "active" ? deactivateTag : activateTag
                    }
                    className="activeCard__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="activeCard__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
              open={show2}
              onClose={handleClose1}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h1
                  style={{
                    margin: "20px",
                    marginTop: "10px",
                    font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                    letterSpacing: "normal",
                  }}
                >
                  Are you sure you want to report found?
                </h1>

                <div style={{ display: "flex" }}>
                  <button
                    onClick={reportFound}
                    className="activeCard__confirm"
                    style={{ margin: "10px 20px" }}
                  >
                    CONFIRM
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="activeCard__cancel"
                    style={{ margin: "10px 20px" }}
                  >
                    CANCEL
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      {showLoader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AcitveEarphoneCard;
