import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Sidebar from "../sidebar/sidebar.component.jsx";
import "./notifications.component.css";
// import CatDog from "../../assets/catdog1.jpg";
// import Mapimage from "../../assets/map.png";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import { QrReader } from "react-qr-reader";
// import search from "../../assets/search.png";
// import qrcode from "../../assets/qr-code.png";
import { switchUrls } from "../../api/index.js";
const vehicle_frontned = switchUrls("vehicle_frontend");

const baseURL = switchUrls("gateway");
function Notifications() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  let usertoken = readCookie("token");
  const token = usertoken;
  const Name = capitalizeName(localStorage.getItem("name"));
  const [productcategory, setProductcategory] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [tagNumber, setTagNumber] = useState("");
  const [show, setShow] = useState(false);
  const [activatetag, setActivateTag] = useState("");
  const [showtag, setShowTag] = useState(false);
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);
  const [scancount1, setscancount1] = useState("");
  const [scancount2, setscancount2] = useState("");
  const [founderCount, setFounderCount] = useState(0);
  const [scanneddetails, setscanneddetails] = useState(null);
  const [scanmedical, setscanmedical] = useState(null);
  const [founderDetails, setFounderDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [alignment, setAlignment] = React.useState("view-scanned-details");
  const [subMenu, setSubMenu] = useState(true);
  const [sidebar, setSidebar] = useState(true);
  const [show5, setShow5] = useState(false);
  const [loader, setloader] = useState(false);

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlemapclick = (e) => {
    e.preventDefault();
    window.location.href = `https://www.google.com/maps/search/?api=1&query=" +
                                  res.latitude +
                                  "," +
                                  res.longitude`;
  };
  const handleCloseTag = () => {
    setShow(false);
  };
  const handleTagNumberChange = (e) => {
    // console.log(e.target.value, "Valuesss");
    const value = e.target.value.toUpperCase();
    sessionStorage.getItem("tag_number", value);
    // setTagNumber(value);
    // setActivateTag(value);
  };

  useEffect(() => {
    if (tagNumber) {
      axios
        .get("/api/getTagNumber", {
          params: {
            tag: tagNumber,
          },
        })
        .then((res) => {
          var data = res.data.tagResult.product_category;
          // console.log(data, "resshhshh");
          setProductcategory(data);
          // showinitialletter(data);
        })
        .catch((error) => {});
    }
  });

  const renderQRModal = () => {
    return (
      <Dialog
        open={show5}
        fullWidth
        maxWidth="xs"
        onClose={() => setShow5(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setTagNumber(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setShow5(false);
              }

              if (error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  const month = [{ month: "short" }, { year: "numeric" }];
  const day = [{ day: "numeric" }];
  const weekday = [{ weekday: "short" }];

  useEffect(() => {
    setloader(true);
    axios
      .get(`${baseURL}/api/getscanlocation/` + localStorage.getItem("uid"), {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response, "response");
        setloader(false);
        setscancount1(response.data.scan_count);
        setFounderCount(response.data.founder_count);

        if (response.data.scan_count > 0) {
          setscanneddetails(response.data.data.scanDetails);
        }
        if (response.data.founder_count > 0) {
          setFounderDetails(response.data.data.founderDetails);
        }
        if (response.data.message) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get("/api/getReportFoundUser/" + localStorage.getItem("uid"), {
        headers: { Authorization: token },
      })
      .then((response) => {
        console.log(response, "response");
        setscancount2(response.data.scan_count);
        if (response.data.scan_count > 0) {
          setscanmedical(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, []);

  function activateTag() {
    const state = {
      tag_number: activatetag.toUpperCase(),
    };
    console.log(state, "state");
    axios
      .post("/api/checkTagNumberWithoutHeader", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const initial = tagNumber.substring(0, 3);
        let productcategory = res.data.tag_data.product_category;
        // const initial = tagNumber.substring(0, 3);
        // console.log(productcategory, "produc");
        if (productcategory === "QR PET") {
          window.location.href = "/pet-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (productcategory === "QR Key") {
          window.location.href = "/key-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (
          productcategory === "QR bag" ||
          productcategory === "QR Bag" ||
          productcategory === "E-Bag" ||
          productcategory === "QR Medical"
        ) {
          window.location.href = "/bag-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (
          productcategory === "QR Passport" ||
          productcategory === "E-Passport"
        ) {
          window.location.href = "/passport-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (productcategory === "QR Gadget") {
          window.location.href = "/earphone-activation/" + activatetag;
          // setShowTag(false);
          sessionStorage.removeItem("tag_number");
        } else if (productcategory === "Generic") {
          window.location.href = "/generic-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (productcategory === "QR Wallet") {
          window.location.href = "/wallet-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        } else if (
          productcategory === "QR Human" ||
          productcategory === "QR HUMAN"
        ) {
          window.location.href = "/human-activation/" + activatetag;
          sessionStorage.removeItem("tag_number");
        }
        else if (
          productcategory === "QR Vehicle"
        ){
          //window.location.href = "/vehicle-activation/" + activatetag;
           window.location.href = vehicle_frontned + "/#/vehicle-activation/" + activatetag;
           sessionStorage.removeItem("tag_number");
          
        }
      })
      .catch((error) => {
        // console.error(error.message);
        const responseData = error.response.data;
        // clearTimeout(loaderTimer);

        // setShowLoader(false);
        if (responseData === "Tag not found by the specified number.") {
          // console.log(responseData, "dateis first");
          // setShow(false);
          setTagNotFound(true);
          // CheckLfTag(state.tag_number);
        } else if (responseData === "Tag is already activated.") {
          // console.log(responseData, "dateis second");
          // clearTimeout(loaderTimer);
          setShow(false);
          setAlreadyActivated(true);
        } else {
          // console.log(res.data, "dateis third");
        }
      });
  }

  // const showSidebar = (e) => {
  //   e.preventDefault();

  //   setSidebar(!sidebar);
  // };
  const showSubMenu = (e) => {
    e.preventDefault();

    setSubMenu(!subMenu);
  };

  function handleLostFound() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleAllTags() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }

  function handleActiveInactive() {
    window.location.href = "/dashboard/" + localStorage.getItem("uid");
  }
  return (
    <>
      <Navbar />

      <div className="notifications-main-wrapper">
        <div className="editPetDetails-left-wrapper">
          <Sidebar
            open={sidebarOpen}
            onClose={handleSidebarToggle}
            handleShow={handleShow}
            handleAllTags={handleAllTags}
            handleActiveInactive={handleActiveInactive}
            handleLostFound={handleLostFound}
            showNotifications={false}
          />
        </div>

        <div className="notifications-right-wrapper">
          <div className="row text-center no-margin">
            <div className="col">
              <ToggleButtonGroup
                className="ToggleButtonGroup"
                exclusive
                value={alignment}
                onChange={handleAlignment}
              >
                <ToggleButton
                  value="view-scanned-details"
                  className="mr-3 ToggleButton text-center"
                  style={{
                    width: "250px",
                    height: "120px",
                    border: "1px solid gray",
                  }}
                >
                  <ul
                    className="text-center"
                    style={{ paddingLeft: "0rem", listStyle: "none" }}
                  >
                    <li
                      className="font-weight-bold qr_scans_text"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      QR Scans
                    </li>

                    <li
                      className="font-weight-bold mt-4"
                      style={{
                        color: "black",
                        fontSize: "40px",
                        textTransform: "none",
                      }}
                    >
                      {scancount1 || 0}
                    </li>
                  </ul>
                </ToggleButton>

                <ToggleButton
                  value="pet-medical-details"
                  className="ml-3 ToggleButton"
                  style={{
                    width: "250px",
                    height: "120px",
                    border: "1px solid gray",
                  }}
                >
                  <ul
                    className="text-center"
                    style={{ paddingLeft: "0rem", listStyle: "none" }}
                  >
                    <li
                      className="font-weight-bold medical_details_text"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Medical Details Accessed
                    </li>

                    <li
                      className="font-weight-bold mt-3"
                      style={{
                        color: "black",
                        fontSize: "40px",
                        textTransform: "none",
                      }}
                    >
                      {scancount2 || 0}
                    </li>
                  </ul>
                </ToggleButton>
                <ToggleButton
                  value="founder-details"
                  className="ml-3 ToggleButton"
                  style={{
                    width: "250px",
                    height: "120px",
                    border: "1px solid gray",
                  }}
                >
                  <ul
                    className="text-center"
                    style={{ paddingLeft: "0rem", listStyle: "none" }}
                  >
                    <li
                      className="font-weight-bold founder_details_text"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        textTransform: "none",
                      }}
                    >
                      Finder Details
                    </li>

                    <li
                      className="font-weight-bold mt-3"
                      style={{
                        color: "black",
                        fontSize: "40px",
                        textTransform: "none",
                      }}
                    >
                      {founderCount || 0}
                    </li>
                  </ul>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          {alignment === "view-scanned-details" ? (
            <div className="mt-3">
              <h3 className="text-center mt-1 font-weight-bold">
                QR Scan History
              </h3>
              <hr style={{ marginBottom: "1rem" }} />

              {scanneddetails ? (
                <div className="mt-1">
                  {scanneddetails.map((res) => {
                    if (res?.image_location && res?.scan_address) {
                      return (
                        <>
                          <div
                            className="px-5 paddingleft text-left"
                            style={{
                              backgroundColor:
                                res.scan_type === "found"
                                  ? "#f0f8ff"
                                  : "transparent", // Background for "found"
                              paddingTop: "20px",
                              height: "auto",
                            }}
                          >
                            <div className="row align-items-center">
                              {/* Left - Date */}
                              <div className="col-1 text-left">
                                <ul
                                  style={{
                                    listStyle: "none",
                                    marginLeft: "-55px",
                                  }}
                                >
                                  <li>
                                    <span style={{ fontSize: "11px" }}>
                                      {join(
                                        new Date(res.scan_date),
                                        weekday,
                                        ""
                                      )}
                                    </span>
                                  </li>

                                  <li>
                                    <span style={{ fontSize: "22px" }}>
                                      <strong>
                                        {join(new Date(res.scan_date), day, "")}
                                      </strong>
                                    </span>
                                  </li>

                                  <li>
                                    <span style={{ fontSize: "11px" }}>
                                      {join(
                                        new Date(res.scan_date),
                                        month,
                                        " "
                                      )}
                                    </span>
                                  </li>

                                  {/* Status for smaller screens (below date) */}
                                  {res.scan_type === "found" && (
                                    <li className="d-md-none">
                                      <span
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        Status
                                      </span>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: "green",
                                        }}
                                      >
                                        {res.scan_type || "Not Available"}
                                      </span>
                                    </li>
                                  )}
                                </ul>
                              </div>

                              {/* Center - Image and Address */}
                              <div className="col-8 d-flex flex-column flex-md-row align-items-start align-items-md-center">
                                <div
                                  className="text-center imagecol"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <img
                                    src={res?.image_location}
                                    width="80px"
                                    height="80px"
                                    style={{ borderRadius: "50%" }}
                                    alt="scan image"
                                  />
                                </div>

                                <div
                                  className="adresscol"
                                  style={{
                                    maxWidth: "70%",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {res.tag_number}{" "}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>
                                      {res.scan_type === "found"
                                        ? "Finder's Address"
                                        : "Address"}
                                    </strong>{" "}
                                    -{" "}
                                    <a
                                      href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                                      style={{
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {res?.scan_address}
                                    </a>
                                  </span>

                                  <br />
                                  <span style={{ fontSize: "13px" }}>
                                    <strong>Time</strong> -{" "}
                                    {(
                                      "0" + new Date(res.scan_date).getHours()
                                    ).substr(-2)}
                                    :
                                    {(
                                      "0" + new Date(res.scan_date).getMinutes()
                                    ).substr(-2)}
                                  </span>
                                </div>
                              </div>

                              {/* Right - Status for larger screens */}
                              {res.scan_type === "found" && (
                                <div
                                  className="col-3 text-right d-none d-md-block"
                                  style={{ marginTop: "10px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Status
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      color: "green",
                                    }}
                                  >
                                    {res.scan_type
                                      ? res.scan_type.charAt(0).toUpperCase() +
                                        res.scan_type.slice(1)
                                      : "Not Available"}
                                  </span>
                                </div>
                              )}
                            </div>

                            <hr style={{ marginBottom: "0rem" }} />
                          </div>
                        </>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : (
                <div className="text-center">
                  {message || "Scan details are not available."}
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {alignment === "pet-medical-details" ? (
            <div>
              <h3 className="mt-5 px-5 paddingleft text-center font-weight-bold">
                Medical data Access History
              </h3>
              <hr />
              {scanmedical ? (
                <div className="mt-5">
                  {scanmedical.map((res) => {
                    return (
                      <div
                        className="px-5 paddingleft text-left"
                        style={{ width: "100%" }}
                      >
                        <div className="row">
                          <div className="col-1 text-left">
                            <ul
                              style={{
                                listStyle: "none",
                                marginLeft: "-40px",
                              }}
                            >
                              <li>
                                <span style={{ fontSize: "11px" }}>
                                  {new Date(res.found_date).toLocaleString(
                                    "en-US",
                                    { weekday: "long" }
                                  )}
                                </span>
                              </li>
                              <li>
                                <span style={{ fontSize: "22px" }}>
                                  <strong>
                                    {new Date(res.found_date).toLocaleString(
                                      "en-US",
                                      { day: "2-digit" }
                                    )}
                                  </strong>
                                </span>
                              </li>
                              <li>
                                <span style={{ fontSize: "11px" }}>
                                  {new Date(res.found_date).toLocaleString(
                                    "en-US",
                                    { month: "long" }
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-2 text-center imagecol">
                            {res?.product_category === "QR HUMAN" ||
                            res?.product_category === "QR Human" ? (
                              <img
                                src={
                                  "https://png.pngtree.com/png-vector/20190621/ourmid/pngtree-human-pose-icon-graphic-design-template-vector-png-image_1497707.jpg"
                                }
                                width="80px"
                                height="80px"
                                style={{ borderRadius: "50%" }}
                                alt="cat and dog"
                              />
                            ) : (
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qrtagdev/assets/catdog1.jpg"
                                }
                                width="80px"
                                height="80px"
                                style={{ borderRadius: "50%" }}
                                alt="cat and dog"
                              />
                            )}
                          </div>
                          <div className=" adresscol">
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "550",
                              }}
                            >
                              {res.tag_number}
                            </span>
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              <strong>Address</strong> - {res.address}
                            </span>
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              <strong>Time</strong> -{" "}
                              {new Date(res.found_date).toLocaleTimeString(
                                "en-US",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }
                              )}
                            </span>
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              <strong>Name</strong> - {res.fullname}
                            </span>
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              <strong>Email</strong> - {res.email}
                            </span>
                            <br />
                            <span style={{ fontSize: "13px" }}>
                              <strong>Phone Number</strong> - {res.phonecode}{" "}
                              {res.phone}
                            </span>
                            <br />
                          </div>
                          <div className="col-2">
                            <a
                              href={
                                "https://www.google.com/maps/search/?api=1&query=" +
                                res.latitude +
                                "," +
                                res.longitude
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={
                                  "https://storage.googleapis.com/pettag/qrtagdev/assets/map.png"
                                }
                                width="50px"
                                height="50px"
                                alt="map"
                              />
                            </a>
                          </div>
                        </div>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center"></div>
              )}
            </div>
          ) : (
            ""
          )}
          {alignment === "founder-details" && (
            <div>
              <h3 className="mt-5 text-center font-weight-bold">
                Finder Details
              </h3>

              <hr />

              {founderDetails.length > 0 ? (
                <div className="mt-5">
                  {founderDetails.map((res, index) => (
                    <div key={index} className="px-5 paddingleft text-left">
                      <div className="row">
                        <div className="col-1 text-left">
                          <ul
                            style={{
                              listStyle: "none",
                              marginLeft: "-40px",
                            }}
                          >
                            <li>
                              <span style={{ fontSize: "11px" }}>
                                {new Date(res.scan_date).toLocaleString(
                                  "en-US",
                                  { weekday: "long" }
                                )}
                              </span>
                            </li>
                            <li>
                              <span style={{ fontSize: "22px" }}>
                                <strong>
                                  {new Date(res.scan_date).toLocaleString(
                                    "en-US",
                                    { day: "2-digit" }
                                  )}
                                </strong>
                              </span>
                            </li>
                            <li>
                              <span style={{ fontSize: "11px" }}>
                                {new Date(res.scan_date).toLocaleString(
                                  "en-US",
                                  { month: "long" }
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-2 text-center imagecol">
                          <img
                            src={
                              res?.image_location ||
                              "https://storage.googleapis.com/pettag/qr/assets/search.png"
                            }
                            width="80px"
                            height="80px"
                            style={{ borderRadius: "50%" }}
                            alt="founder"
                          />
                        </div>
                        <div className="adresscol">
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: "550",
                            }}
                          >
                            {res.founder_firstname} {res.founder_lastname}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Email</strong> - {res.founder_email}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Contact Number</strong> -{" "}
                            {res.founder_phcode} {res.founder_contactno}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Tag Number</strong> - {res.tag_number}
                          </span>
                          <br />
                          <span style={{ fontSize: "13px" }}>
                            <strong>Time</strong> -{" "}
                            {new Date(res.scan_date).toLocaleTimeString(
                              "en-US",
                              { hour: "2-digit", minute: "2-digit" }
                            )}
                          </span>
                          <br />
                          {/* <span style={{ fontSize: "13px" }}>
                            <strong>Location</strong> -{" "}
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${res.latitude},${res.longitude}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View on map
                            </a>
                          </span> */}
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  {message || "Finder details are not available."}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={tagNotFound}
        onClose={handleNotFound}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="small__popup">
          <h3>Tag number not found</h3>

          <button className="small_pop_btn" onClick={handleNotFound}>
            OK
          </button>
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={alreadyActivated}
        onClose={handleActivated}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="small__popup">
          <h3>Tag is already activated</h3>

          <button
            className="otp-buttons small_pop_btn"
            onClick={handleActivated}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={show}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <h2 className="dashboard-activateTag-popup">Tag Activation</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5em",
            }}
          >
            <img
              src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
              style={{ width: "30px", height: "30px" }}
              alt="QR Code"
              onClick={() => setShow5(true)}
            />
            <input
              className="dashboard-input__field__dialogs"
              onKeyUp={(e) => {
                e.target.value = e.target.value
                  .replace(/\s/g, "")
                  .toUpperCase();
              }}
              value={activatetag}
              onChange={(e) => {
                const inputValue = e.target.value
                  .replace(/\s/g, "")
                  .toUpperCase()
                  .slice(0, 10);
                setActivateTag(inputValue);
              }}
              placeholder="Owner ID"
              maxLength={10}
            />
          </div>

          <div
            style={{
              padding: "1em",
              marginBottom: "1px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <button
              className="dashboard-verifyOtp-button"
              onClick={activateTag}
            >
              ACTIVATE
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {renderQRModal()}

      {loader && (
        <div className="loader-container">
          <div className="loader">
            <img
              src={"https://storage.googleapis.com/pettag/qr/assets/loader.gif"}
              alt="Loading..."
              loading="lazy"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Notifications;
