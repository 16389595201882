import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import "./card.component.css";
import Dialog from "@material-ui/core/Dialog";
// import Details from "../../assets/writing_749000.webp";
// import petPhoto from "../../assets/passport-icon.png";
import Tooltip from "@material-ui/core/Tooltip";
import "./passportcard.component.css";
import { DialogTitle, DialogActions } from "@material-ui/core";
import Countdown from "../countdowntimer/Countdown";
import { APP_ENV, switchUrls } from "../../api";
const gatewayUrl = switchUrls("gateway");
const passport_frontend = switchUrls("passport_frontend");

function ActivePassportcard({
  passport_imagelocation,
  valuable_type,
  valuable_desc,
  passport,
  Surname,
  givenName,
  nationality,
  placeofissue,
  address,
  dateofissue,
  validityperiod,
  tag_number,
  activation_date,
  tag_status,
  lf_status,
  expirydate,
  digital_product,
  journey_startdate,
  journey_enddate,
  deactivation_date,
  user_region,
}) {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }

  const token = readCookie("token");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const uid = localStorage.getItem("uid");
  const downloadQr = async (tag_number) => {
    try {
      setShowLoader(true);
      const ipApiResponse = await fetch("https://ipapi.co/json/");
      const country = await ipApiResponse.json();
      const ipAddress = country.country;

      const { data } = await axios.post(
        `${gatewayUrl}/api/downloadqrtag`,

        { tag_number: tag_number, type: "passport", ip: ipAddress },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Tell axios to expect binary data
        }
      );
      console.log(data);

      // Create a download link for the PDF
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = "E-tag.pdf";
      link.click();
      window.URL.revokeObjectURL(url);
      setShowLoader(false);
    } catch (er) {
      console.log(er);
    }
  };
  // Convert Camel Case
  const convertCamelCase = (sentense) => {
    if (typeof sentense !== "string") {
      return sentense;
    }
    const words = sentense?.split(" ");

    const capitalizeFirstWord = words?.map((word) => {
      if (word.length > 0) {
        return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase();
      } else {
        return word;
      }
    });
    const capitalizeFirstWordSentese = capitalizeFirstWord?.join(" ");
    return capitalizeFirstWordSentese;
  };
  const handleConfirm = () => {
    setShow(false);

    //  tag_status === "active" ? deactivateTag : activateTag;
    if (tag_status === "active") {
      deactivateTag();
    } else {
      activateTag();
    }

    window.location.reload();
  };

  function activateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/passportactiveTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Activated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function deactivateTag() {
    const state = {
      tag_number: tag_number,
    };

    axios
      .post("/api/passportdeactivateTag", state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag is Deactivated") {
          window.location.href = "/dashboard/" + uid;
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  // define the function that finds the users geolocation
  const getUserLocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          axios
            .post(
              "/api/passportreportfoundstatus",
              { tag_number, latitude, longitude },
              {
                headers: {
                  Authorization: token,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data === "Tag is reported found") {
                window.location.href = "/dashboard/" + uid;
              }
            })
            .catch((error) => {
              console.error(error.message);
            });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        },
        { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  function reportFound() {
    getUserLocation();
  }
  const handleQrClick = () => {
    window.location.href = `/#/scan/${tag_number}`;
  };
  const convertISOStringToMonthDay = (date) => {
    if (digital_product && user_region) {
      const tempDate = new Date(date);

      // Format the date directly in the user's local time zone
      const day = tempDate.toLocaleString("en-US", {
        day: "2-digit",
        timeZone: user_region,
      });
      const month = tempDate.toLocaleString("en-US", {
        month: "short",
        timeZone: user_region,
      });
      const year = tempDate.toLocaleString("en-US", {
        year: "numeric",
        timeZone: user_region,
      });
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      const tempDate = new Date(date);
      const day = tempDate.getUTCDate().toString().padStart(2, "0");
      const month = tempDate.toLocaleString("default", { month: "short" });
      const year = tempDate.getUTCFullYear();
      const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setShow(false);
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  function foundOrLost() {
    if (lf_status === "lost") {
      setShow2(true);

      handleClose();
    } else {
      const redirectLink = "/report-lost/" + tag_number;
      window.location.href = redirectLink;
    }
  }

  return (
    <>
      <div className={digital_product ? "card_main_digital" : "card_main"}>
        <div
          className={
            digital_product ? "card_main_div_digital" : "card_main_div"
          }
        >
          <div className="imagedetails">
            <div>
              <div
                style={{
                  display: imageLoaded ? "none" : "block",
                  width: "100px",
                  height: "100px",
                }}
              >
                <img
                  src="https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  alt="Loading..."
                  loading="lazy"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              {passport_imagelocation ? (
                <>
                  <img
                    className="pet-img"
                    src={passport_imagelocation}
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: "15px",
                      display: imageLoaded ? "block" : "none",
                    }}
                    onLoad={handleImageLoad}
                  />
                </>
              ) : (
                <>
                  <img
                    className="pet-img"
                    src="https://storage.googleapis.com/pettag/qr/assets/passport.png"
                    alt=""
                    style={{
                      width: 100,
                      height: 100,
                      marginTop: "15px",
                      display: imageLoaded ? "block" : "none",
                    }}
                    onLoad={handleImageLoad}
                  />
                </>
              )}
              <div className="qrscan-img">
                <img
                  alt="qr-img"
                  src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                  style={{ width: "20px", height: "20px" }}
                />
                <p style={{ fontSize: "0.85rem" }}>
                  <b>{tag_number && tag_number !== "-" ? tag_number : "N/A"}</b>
                </p>
              </div>

              {digital_product ? (
                <>
                  <div className="mobile-view">
                    <ul
                      className="tagdetails"
                      style={{
                        listStyle: "none",
                        color: "black",
                        textAlign: "right",
                        marginRight: 20,
                        fontSize: "12px",
                        lineHeight: "normal",
                      }}
                    >
                      {lf_status === "lost" ? (
                        <li className="petloststatus mobile-view-lostpetstatus">
                          <span>Reported Lost</span>
                        </li>
                      ) : (
                        <li className="petstatus mobile-view-petstatus-passport">
                          {digital_product ? (
                            <>
                              <Tooltip
                                title={
                                  tag_status === "inactive"
                                    ? "Tag will activate Soon"
                                    : tag_status === "deactive"
                                    ? "Tag Deactivated"
                                    : tag_status === "active"
                                    ? "Tag is Active"
                                    : "Tag Expired"
                                }
                                aria-label="add"
                              >
                                <button
                                  className={
                                    tag_status === "active"
                                      ? "activatedeactivatebutton bg-success mt-4"
                                      : "activatedeactivatebutton bg-danger mt-4"
                                  }
                                  style={{ marginRight: "4px" }}
                                ></button>
                              </Tooltip>
                              {tag_status !== "inactive" ? (
                                <>
                                  <span>{`${
                                    tag_status.charAt(0).toUpperCase() +
                                    tag_status.slice(1)
                                  }`}</span>
                                </>
                              ) : (
                                <span>Inactive</span>
                              )}
                            </>
                          ) : (
                            <>
                              {tag_status === "active" ? (
                                <Tooltip
                                  title="Click here to deactivate tag"
                                  aria-label="add"
                                >
                                  <button
                                    className="activatedeactivatebutton bg-success mt-4"
                                    onClick={() => {
                                      setShow(true);
                                      handleClose();
                                    }}
                                    style={{ marginRight: "4px" }}
                                  ></button>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title="Click here to activate tag"
                                  aria-label="add"
                                >
                                  <button
                                    className="activatedeactivatebutton bg-danger mt-4"
                                    onClick={() => {
                                      setShow(true);
                                      handleClose();
                                    }}
                                    style={{ marginRight: "4px" }}
                                  ></button>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              ) : (
                <div className="mobile-view">
                  <ul
                    className="tagdetails"
                    style={{
                      listStyle: "none",
                      color: "black",
                      textAlign: "right",
                      marginRight: 20,
                      fontSize: "12px",
                      lineHeight: "normal",
                    }}
                  >
                    {/* <li className="petownerid">
              <span>{tag_number}</span>
            </li> */}
                    {lf_status === "lost" ? (
                      <li className="petloststatus mobile-view-lostpetstatus">
                        <span>Reported Lost</span>
                      </li>
                    ) : (
                      <li className="petstatus mobile-view-petstatus-passport">
                        {digital_product ? (
                          <>
                            <Tooltip
                              title={
                                tag_status === "inactive"
                                  ? "Tag will activate Soon"
                                  : tag_status === "deactive"
                                  ? "Tag Deactivated"
                                  : tag_status === "active"
                                  ? "Tag is Active"
                                  : "Unknown Tag Status"
                              }
                              aria-label="add"
                            >
                              <button
                                className={
                                  tag_status === "active"
                                    ? "activatedeactivatebutton bg-success mt-4"
                                    : "activatedeactivatebutton bg-danger mt-4"
                                }
                                style={{ marginRight: "4px" }}
                              ></button>
                            </Tooltip>
                            <span>{`${
                              tag_status.charAt(0).toUpperCase() +
                              tag_status.slice(1)
                            }`}</span>
                          </>
                        ) : (
                          <>
                            {tag_status === "active" ? (
                              <Tooltip
                                title="Click here to deactivate tag"
                                aria-label="add"
                              >
                                <button
                                  className="activatedeactivatebutton bg-success mt-4"
                                  onClick={() => {
                                    setShow(true);
                                    handleClose();
                                  }}
                                  style={{ marginRight: "4px" }}
                                ></button>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="Click here to activate tag"
                                aria-label="add"
                              >
                                <button
                                  className="activatedeactivatebutton bg-danger mt-4"
                                  onClick={() => {
                                    setShow(true);
                                    handleClose();
                                  }}
                                  style={{ marginRight: "4px" }}
                                ></button>
                              </Tooltip>
                            )}
                            <span>{`${
                              tag_status.charAt(0).toUpperCase() +
                              tag_status.slice(1)
                            }`}</span>
                          </>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              )}
                <div className="mobile-view">
              {digital_product &&
              journey_startdate &&
              journey_enddate &&
              tag_status === "inactive" ? (
                <>
                  <Countdown travelstartDate={journey_startdate} />
                </>
              ) : null}
              </div>
            </div>
          </div>

          <div
            className={
              digital_product ? "middle-content_digital" : "middle-content"
            }
          >
            <ul className="geniecardtext-ul">
              {valuable_type ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    {" "}
                    Valuable Type:{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {convertCamelCase(valuable_type)}
                  </span>
                </li>
              ) : (
                ""
              )}
              {passport ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    {" "}
                    Passport:{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {passport}
                  </span>
                </li>
              ) : (
                ""
              )}
              {nationality ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    Nationality:{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {convertCamelCase(nationality)}
                  </span>
                </li>
              ) : (
                ""
              )}
              {dateofissue ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    {" "}
                    Date Of Issue:{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {convertISOStringToMonthDay(dateofissue)}
                  </span>
                </li>
              ) : (
                ""
              )}
              {validityperiod ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    {" "}
                    Validity Period:{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {validityperiod}
                  </span>
                </li>
              ) : (
                ""
              )}
              {expirydate ? (
                <li className="petdetails">
                  <span className={digital_product ? "digital_lebal" : "lebal"}>
                    {" "}
                    Expiry Date :{" "}
                  </span>
                  <span
                    className={
                      digital_product
                        ? "digital_lebal-content"
                        : "lebal-content"
                    }
                  >
                    {convertISOStringToMonthDay(expirydate)}
                  </span>
                </li>
              ) : (
                ""
              )}
              {digital_product ? (
                <>
                  <li className="petdetails">
                    <span
                      className={digital_product ? "digital_lebal" : "lebal"}
                    >
                      Travel Date:{" "}
                    </span>
                    <span
                      className={
                        digital_product
                          ? "digital_lebal-content"
                          : "lebal-content"
                      }
                    >
                      {convertISOStringToMonthDay(journey_startdate)}-{" "}
                      {convertISOStringToMonthDay(journey_enddate)}
                    </span>
                  </li>
                  <div className="passport_warning_desktop">
                    {new Date(journey_enddate).setMonth(
                      new Date(journey_enddate).getMonth() + 6
                    ) > new Date(expirydate) ? (
                      <span className="warning_text_desktop">
                        Alert : Passport expires within 6 months of travel end
                        date
                      </span>
                    ) : null}
                  </div>
                </>
              ) : null}
              {activation_date ? (
                <>
                  {tag_status === "active" ? (
                    <>
                      <li className="petdetails date-laptopview">
                        <span
                          className={
                            digital_product ? "digital_lebal" : "lebal"
                          }
                        >
                          Active Date:{" "}
                        </span>
                        <span
                          span
                          className={
                            digital_product
                              ? "digital_lebal-content"
                              : "lebal-content"
                          }
                        >{` ${convertISOStringToMonthDay(
                          activation_date
                        )}`}</span>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
              {deactivation_date ? (
                <>
                  {tag_status === "deactive" ? (
                    <>
                      <li className="petdetails date-laptopview">
                        <span
                          className={
                            digital_product ? "digital_lebal" : "lebal"
                          }
                        >
                          Deactivation Date:{" "}
                        </span>
                        <span
                          span
                          className={
                            digital_product
                              ? "digital_lebal-content"
                              : "lebal-content"
                          }
                        >{` ${convertISOStringToMonthDay(
                          deactivation_date
                        )}`}</span>
                      </li>
                    </>
                  ) : null}
                </>
              ) : null}
            </ul>

            <ul
              className="tagdetails laptop-view"
              style={{
                listStyle: "none",
                color: "black",
                textAlign: "right",
                marginRight: digital_product ? 0 : 20,
                fontSize: "12px",
                lineHeight: "normal",
              }}
            >
              {/* <li className="petownerid">
                  <span>{tag_number}</span>
                </li> */}
              {lf_status === "lost" ? (
                <li className="petstatus mt-3">
                  <span>Reported Lost</span>
                </li>
              ) : (
                <li className="petstatus">
                  {digital_product ? (
                    <>
                      <Tooltip
                        title={
                          tag_status === "inactive"
                            ? "Tag will activate Soon"
                            : tag_status === "deactive"
                            ? "Tag Deactivated"
                            : tag_status === "active"
                            ? "Tag is Active"
                            : "Tag Expired"
                        }
                        aria-label="add"
                      >
                        <button
                          className={
                            tag_status === "active"
                              ? "activatedeactivatebutton bg-success mt-4"
                              : "activatedeactivatebutton bg-danger mt-4"
                          }
                          style={{ marginRight: "4px" }}
                        ></button>
                      </Tooltip>
                      {tag_status === "active" ? (
                        <>
                          <span>{`${
                            tag_status.charAt(0).toUpperCase() +
                            tag_status.slice(1)
                          }`}</span>
                        </>
                      ) : (
                        <span>Inactive</span>
                      )}
                    </>
                  ) : (
                    <>
                      {tag_status === "active" ? (
                        <Tooltip
                          title="Click here to deactivate tag"
                          aria-label="add"
                        >
                          <button
                            className="activatedeactivatebutton bg-success mt-4"
                            onClick={() => {
                              setShow(true);
                              handleClose();
                            }}
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Click here to activate tag"
                          aria-label="add"
                        >
                          <button
                            className="activatedeactivatebutton bg-danger mt-4"
                            onClick={() => {
                              setShow(true);
                              handleClose();
                            }}
                            style={{ marginRight: "4px" }}
                          ></button>
                        </Tooltip>
                      )}
                      <span>{`${
                        tag_status.charAt(0).toUpperCase() + tag_status.slice(1)
                      }`}</span>
                    </>
                  )}
                </li>
              )}
              {digital_product ? (
                <>
                  {tag_status === "inactive" ? <>

                  
{digital_product &&
journey_startdate &&
journey_enddate &&
tag_status === "inactive" && 

<li style={{marginTop:"1rem"}}>

<Countdown travelstartDate={journey_startdate} type={"bag"} />


</li>
}

</> : (
                    <li className="petactivationdate">
                      {tag_status === "active" ? (
                        <>
                          <span className="petactive-lebal">Active Date</span>
                          <span
                            className={
                              digital_product
                                ? "digital_lebal-content"
                                : "lebal-content"
                            }
                          >{` ${convertISOStringToMonthDay(
                            activation_date
                          )}`}</span>
                        </>
                      ) : (
                        <>
                          {deactivation_date && (
                            <>
                              <span className="petactive-lebal">
                                Deactivated On:
                              </span>
                              <span
                                className={
                                  digital_product
                                    ? "digital_lebal-content"
                                    : "lebal-content"
                                }
                              >{` ${convertISOStringToMonthDay(
                                deactivation_date
                              )}`}</span>
                            </>
                          )}
                        </>
                      )}
                    </li>
                  )}
                </>
              ) : (
                <li className="petactivationdate">
                  <span className="petactive-lebal">Active Date:</span>
                  <span className="lebal-content">{` ${convertISOStringToMonthDay(
                    activation_date
                  )}`}</span>
                  {digital_product && tag_status === "deactive" ? (
                    <>
                      <li className="petactivationdate">
                        <span className="petactive-lebal">Deactivated On:</span>
                        <span className="lebal-content">{` ${convertISOStringToMonthDay(
                          activation_date
                        )}`}</span>
                      </li>
                    </>
                  ) : null}
                </li>
              )}
            </ul>
          </div>

          <div
            className={
              digital_product
                ? "tagdetailsandicons_digital"
                : "tagdetailsandicons"
            }
          >
            <div className="icons" style={{ marginRight: "30px" }}>
            {digital_product &&
              (tag_status === "active" || tag_status === "inactive") ? (
                <>
                  <Tooltip title="Download eTag" aria-label="add">
                    <p style={{cursor:"pointer"}}  onClick={()=>downloadQr(tag_number)}>
                      <img
                        className="images-css"
                        src={"https://storage.googleapis.com/pettag/qr/assets/icondownload.png"}
                        alt="Pet Icon"
                      />
                    </p>
                  </Tooltip>
                </>
              ) : null}
              <Tooltip title="Click here to try QR Scan" aria-label="add">
                <div onClick={handleQrClick} style={{ cursor: "pointer" }}>
                  <img
                    className="images-css"
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/qr.png"
                    }
                    alt="Pet Icon"
                  />
                </div>
              </Tooltip>

              {tag_status === "active" || tag_status === "inactive" ? (
                <>


                  <Tooltip title="passportdetails" aria-label="add">
                    <a
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 5000);
                        if (APP_ENV === "local") {
                          window.location.href =
                            passport_frontend +
                            "/#/passport-details/" +
                            tag_number;
                        } else {
                          window.location.href =
                            "/passport-details/" + tag_number;
                        }
                      }}
                    >
                      {/* <i
                    style={{
                      color: "black",
                      fontSize: 18,
                      marginTop: 10,
                      marginRight: 1,
                    }}
                  ></i> */}

                      <img
                        className="images-css"
                        src={
                          "https://storage.googleapis.com/pettag/qr/assets/edits.png"
                        }
                        alt="Pet Icon"
                      />
                    </a>
                  </Tooltip>
                  {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                </>
              ) : null}
         

              {tag_status === "active" || tag_status === "inactive" ? (
                <>
                  <i
                    aria-controls="menu-list-grow"
                    onClick={handleClick}
                    className="fa fa-ellipsis-v menu-drop"
                    style={{
                      color: "#ffc200f7",
                      fontSize: 27,
                      marginTop: 5,
                      // marginRight: "-40px",
                      // marginLeft: "-10px",
                      // paddingLeft: "10px",
                      // paddingRight: "10px",
                    }}
                  ></i>
                </>
              ) : null}

          
              {tag_status === "active" || tag_status === "inactive" ? (
                <>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
               {
                tag_status === "active" &&      <MenuItem
                onClick={() => {
                  if (lf_status === "lost") {
                    setShow2(true);

                    handleClose();
                  } else {
                    // window.location.href =
                    //   "/passport/report-lost/" + tag_number;
                    let lfstatus = "lost";
                    axios
                      .post(
                        "/api/passportreportlost/" + tag_number,
                        { lfstatus },
                        {
                          headers: { Authorization: token },
                        }
                      )
                      .then((response) => {
                        // console.log(response);
                        if (
                          response.data ===
                          "Passport Tag is reported Lost"
                        ) {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }
                      })
                      .catch((error) => {
                        // console.log(error);
                      });

                    setShowLoader(true);
                    setTimeout(() => {
                      setShowLoader(false);
                    }, 5000);
                  }
                }}
              >
                {lf_status === "lost" ? "Report Found" : "Report Lost"}
              </MenuItem>
               }

                    {!digital_product && (
                      <MenuItem
                        onClick={() => {
                          setShow(true);
                          handleClose();
                        }}
                      >
                        {tag_status === "active"
                          ? "Deactivate Tag"
                          : "Activate Tag"}
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={() => {
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 5000);
                        window.location.href =
                          "/passport-details/" + tag_number;
                        //window.location.href =
                        //"http://localhost:3107/#/passport-details/" + tag_number;
                      }}
                    >
                      Passport Details
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        window.location.href = "/#/scan/" + tag_number;
                      }}
                    >
                      Preview QR Scan
                    </MenuItem>
                    {digital_product ? (
                      <MenuItem
                        onClick={() => {
                          downloadQr(tag_number);
                        }}
                      >
                        Download eTag
                      </MenuItem>
                    ) : null}
                  </Menu>
                </>
              ) : null}
              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show}
                onClose={handleClose1}
                // aria-labelledby="form-dialog-title"
              >
                <DialogTitle style={{ fontFamily: "Prompt" }}>
                  {tag_status === "active"
                    ? "Are you sure you want to deactivate the tag?"
                    : "Are you sure you want to activate the tag?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button
                    // onClick={tag_status === "active" ? deactivateTag : activateTag}
                    onClick={handleConfirm}
                    className="activeCard__confirm"
                  >
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
              <Dialog
                PaperProps={{
                  style: { borderRadius: 15 },
                }}
                open={show2}
                onClose={handleClose1}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle>
                  {"Are you sure you want to report found?"}
                </DialogTitle>

                <DialogActions style={{ margin: "0px 1em 1em 1em" }}>
                  <button onClick={reportFound} className="activeCard__confirm">
                    Confirm
                  </button>

                  <button
                    onClick={() => setShow2(false)}
                    className="activeCard__cancel"
                  >
                    Cancel
                  </button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
        {digital_product && journey_startdate && journey_enddate ? (
          <>
            <div className="passport_warning">
              {new Date(journey_enddate).setMonth(
                new Date(journey_enddate).getMonth() + 6
              ) > new Date(expirydate) ? (
                <span className="warning_text">
                  Alert: Passport expires within 6 months of travel end date
                </span>
              ) : null}
            </div>
            <div>
           
            </div>
          </>
        ) : null}
        {showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ActivePassportcard;
